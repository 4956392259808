<template>
    <div class="contents">
        <div style="display: flex;justify-content: space-between;align-items: center;">
            <h1 style="height: 50px;line-height: 50px;margin-top:50px;">{{ $t("top-select-ai-detail-vod-data-list") }}
            </h1>
            <button @click="$router.go(-1)" class="back-btn">목록으로 가기</button>
        </div>
        <div class="flexB" style="display: flex; align-items: stretch;margin-bottom: 15px;">
            <div class="video-list" style="position: relative; width:60%;">
                <div class="video-info-box" id="video-info-box" v-if="contentsId != undefined">
                    <span>{{ vodObj.deviceName == null ? '휴대폰 ' : vodObj.deviceName }}</span>
                    <span>│</span>
                    <span> {{ changePlayTime == null ? ' -' : changePlayTime }}</span>
                </div>
                <videoPlayer
                    ref="video"
                    :src="videoSrc"
                    :contentsId="contentsId"
                    :playerId="'isplay'"
                    :isShowLiveText="false"
                    :isUser="userType == 'User'" :isAiMarkShow="true" @contents-delete="deleteVod"
                    @onTimeUpdate="onTimeUpdate" @onUpdateAiDetecting="onUpdateAiDetecting" @isCanPlay="playerLoadData"
                    @isReadyPlay="playerReady">
                </videoPlayer>
            </div>
            <div class="mapWrap" style="width:39%;">
                <div class="map" style="height: 85%;">
                    <Map ref="map" style="width: 100%; height: 100%; border: 1px solid #d3d3d3;" :loadMapName="loadMap"
                        :isLeftButtonPosition="false" @play-marker-lng-lat="playMarkerLngLat"/>
                        <MapButtonComponent ref="buttonMap" />
                    <span v-if="locationSize == 0"
                        style="position:absolute; z-index:4; top: 50%; left: 50%; transform: translate(-50%, -50%); height:auto; font-size: 2rem; font-weight: bold; color: white; background: rgba(0,0,0,0.8); padding: 2px 8px; ">
                        {{ $t("popup-location-not-found") }}
                    </span>
                </div>

                <div class="mapInfo">
                    <div class="bottom_sc" v-if="videoSrc">
                        <div class="infoData">
                            <span v-if="vodObj.contentsId != undefined" style="margin-right: 0;">
                                <!-- 20240117 update -->
                                <div class="vodInfo flexL" style="flex-direction: column;">
                                    <div id="drInfoBox" class="flex droneVal">
                                        <div class="droneVal-row">
                                            <div class="droneData flex">
                                                <span class="bold DataName">고도</span>
                                                <span class="vodData">{{ doneData.height == null ? 'N/A' :
                                                    `${doneData.height.toFixed(0)} m` }}</span>
                                            </div>
                                            <div class="droneData flex">
                                                <span class="bold DataName">거리</span>
                                                <span class="vodData">{{ doneData.distance == null ? 'N/A' :
                                                    `${doneData.distance.toFixed(0)} m` }}</span>
                                            </div>
                                            <div class="droneData flex">
                                                <span class="bold DataName">수평</span>
                                                <span class="vodData">{{ doneData.hspeed == null ? 'N/A' :
                                                    `${doneData.hspeed.toFixed(0)} m/s` }}</span>
                                            </div>
                                            <div class="droneData flex">
                                                <span class="bold DataName">수직</span>
                                                <span class="vodData">{{ doneData.vspeed == null ? 'N/A' :
                                                    `${doneData.vspeed.toFixed(0)} m/s` }}</span>
                                            </div>
                                        </div>
                                        <div class="droneVal-row">
                                            <div class="droneData flex">
                                                <span class="DataName bold">위도</span>
                                                <span class="vodData" style="margin-right: 0;text-align: end;">{{
                                                    currentLat ==
                                                        null
                                                    ? 'N/A' : `${currentLat.toFixed(9)}` }}</span>
                                            </div>
                                            <div class="droneData flex">
                                                <span class="DataName bold">경도</span>
                                                <span class="vodData" style="margin-right: 0;text-align: end;">{{
                                                    currentLng ==
                                                        null
                                                    ? 'N/A' : `${currentLng.toFixed(9)}` }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="infoContent">
            <div class="box one detailAIVod m-b-0">
                <h1>{{ vodObj.eventTitle }}</h1>
                <h4>
                    <span>{{ vodObj.title }}</span>│
                    <span>{{ vodObj.recordUserName }}</span>│
                    <span>{{ vodObj.registerUserDeptName }}</span>
                </h4>
                <div class="detail-info-box">
                    <div class="info-row">
                        <h4>현장명</h4>
                        <h2>{{ vodObj.eventTitle }}</h2>
                    </div>
                    <div class="info-row">
                        <h4>녹화시간</h4>
                        <h2>{{ moment(vodObj.recordDate).format("YYYY.MM.DD HH:mm:ss") }}</h2>
                    </div>
                    <div class="info-row">
                        <h4>디바이스 명</h4>
                        <h2>{{ vodObj.deviceName == null ? '휴대폰' : vodObj.deviceName }}</h2>
                    </div>
                    <div class="info-row m-b-0">
                        <h4>모델명</h4>
                        <h2>{{ vodObj.deviceModelName == null ? '휴대폰' : vodObj.deviceModelName }}</h2>
                    </div>
                </div>
            </div>
            <div class="aiTableInfo box m-b-0">
                <div class="ai-table tableBox">
                    <table style="table-layout: fixed;">
                        <colgroup>
                            <col style="width: 19%;" />
                            <col style="width: 13%;" />
                            <col style="width: 10%;" />
                            <col style="width: 10%;" />
                            <col style="width: 10%;" />
                            <col style="width: 10%;" />
                            <col style="width: 20%;" />
                        </colgroup>
                        <tr>
                            <th>탐지한 객체</th>
                            <th>정확도&#40;%&#41;</th>
                            <th>x1</th>
                            <th>y1</th>
                            <th>x2</th>
                            <th>y2</th>
                            <th>탐지시간</th>
                        </tr>
                        <tr v-if="aiObservedList === null || aiObservedList.length === 0">
                            <td colspan="7" style="text-align: center;">탐지된 데이터가 없습니다.</td>
                        </tr>
                        <tr v-for="(aiData, index) in aiObservedList" :key="index">
                            <td>{{ aiData.class_name }}</td>
                            <td>{{ percentage(aiData.confidence) }}</td>
                            <td>{{ Math.floor(aiData.x1) }}</td>
                            <td>{{ Math.floor(aiData.y1) }}</td>
                            <td>{{ Math.floor(aiData.x2) }}</td>
                            <td>{{ Math.floor(aiData.y2) }}</td>
                            <td>{{ frameToTime(aiData.current_frame) }}</td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import videoPlayer from "@/components/video/videoPlayer";
import Map from "@/components/map/Map";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";
import MapMarkerData from "@/components/map/data/MapMarkerData";


import { fetchVodUrlList } from "@/api/server";
import { fetchLocation } from "@/api/location";
import { fetchVodDetail } from "@/api/contents";
import { fetchEvent } from "@/api/event";
import { fetchPointList, getPointTypeName } from "@/api/point";
import { playerPopOpen } from "@/util/popup";
import { mapState } from "vuex";
import { getDefaultMapZoomLevel } from "@/util/common";
import { fetchAiVodSrc } from "@/api/file";
import {  fetchResults } from "@/api/ai";

import MapButtonComponent from "@/components/map/MapButtonComponent.vue";

export default {
    components: { videoPlayer, Map,MapButtonComponent },
    name: "AiVodDetail",

    data() {
        return {
            moment: moment,
            loadMap: 'kakao',
            videoKey: 0,

            videoSrc: "", // videoPlayer에 넣어줘야하는 영상 Src => baseURL + vodURL
            eventId: null,
            eventInfo: null,
            contentsId: null,
            vodObj: {},
            isPlayStatus: false,
            changePlayTime: null,
            aiObservedList: null,

            location: null,
            currentLat: null,
            currentLng: null,
            locationSize: null,
            doneData: {
                distance: null,
                hspeed: null,
                vspeed: null,
                height: null,
                battery: null,
                heartRate: null,
            },

        };
    },
    computed: {
        ...mapState({
            currentLang: state => state.store.currentLang,
            userType: state => state.store.userType,
        }),
    },
    watch: {
        currentLang(value) {
            this.initMapData(value);
        },
    },
    mounted() {
        this.initButtonMap()
        this.allOfProcess()
    },
    created() {
        this.eventId = this.$route.params.eventId
        this.contentsId = this.$route.params.contentsId
    },
    methods: {
        /**
         * 최종적으로 location과, aiResultData를 사용하여 맵에 마커를 찍음
         */
        async allOfProcess() {
            try {
                await Promise.all([
                    this.processAiVodUrl(),
                    this.processAiVodInfo()
                ])
                this.setAiDetectedMarker() // 저장된 AiVodResults와 location를 사용해서 마커찍음
            } catch(err) {
                console.error(err)
            }
        },

        /**
         * AiVodUrl 정보를 가져오기 위한 프로세스
         */
        async processAiVodUrl() {
            try {
                const [vodUrl, vodRes] = await Promise.all([
                    this.getVodUrlInfo(),
                    this.getAiVod()
                ])
                this.videoSrc = vodUrl + vodRes.streamUri // videoPlayer에 넘겨줘야할놈
                this.aiObservedList = await this.getAiVodResults(vodRes.referenceAiId)
            } catch(err) {
                console.error(err)
            }
        },

        /**
         * AiVodInfo 를 가져오기 위한 프로세스
         */
        async processAiVodInfo() {
            try {
                // const [eventInfo, vodInfo]
                [this.eventInfo , this.vodObj] = await Promise.all([
                    this.getEvent(),
                    this.getVodInfo()
                ])

                this.changePlayTime = moment(new Date(this.vodObj.recordDate)).format("YYYY-MM-DD HH:mm:ss");

                this.location = await this.getLocation()

                // 라인 그리는 로직
                let mapLineData = new MapLineData();
                mapLineData.id = this.contentsId;
                mapLineData.locationGroup = this.location;
                mapLineData.mapDataType = this.vodObj.deviceName === null ? MapDataType.ID_STREAMER : MapDataType.ID_DRONE;
                this.$refs.map.onPlayCreateData(mapLineData);

                // 제일처음 내위치 찍음
                const first = this.location[0];
                this.currentLng = first.lng;
                this.currentLat = first.lat;
                this.$refs.map.onLngLatToMoveCenter(first.lng, first.lat)
            } catch(err) {
                console.error(err)
            }
        },



        /**
        * GET URLInfo
        * vodUrl 리턴, 차후 videoSrc만들때 사용
        */
        async getVodUrlInfo() {
            const res = await fetchVodUrlList(this.eventId)
            const vodUrl = `${res.data.data.properties.vodStreamUrl}/${res.data.data.properties.application}`
            return vodUrl
        },

        /**
        * GET Ai Vod정보
        * 리턴값에 streamUri, referenceAiId 들어있음
        */
        async getAiVod() {
            const res = await fetchAiVodSrc("AI",this.contentsId)
            if (res.data.result !== 0 || !res.data.data.streamUri) {
                return Promise.reject("result가 0이 아니거나, streamUri가 없음! AiVodDetail-getAiVod()")
            }
            return res.data.data
        },

        /**
         * Ai탐지 results
         * @param aiKey
         */
         async getAiVodResults(aiKey) {
            const params = {
                media_key: aiKey,
                conf: 0.7
            }
            const res = await fetchResults(params)
            const sortedResult = res.data.sort((a, b) => {
                return a.frame_number - b.frame_number
            })
            

            
            let lastFrame = 0
            let aiList = [];

            sortedResult.forEach((aiData) => {
                aiData.bboxes.forEach((box) => {
                    
                    // 탐지된적이 없는 객체명일경우, 시간초가 1초가 넘어간 경우 확인
                    if ( aiList.length === 0 || aiData.frame_number > (lastFrame + 30)) {
                        aiList.push({
                            ...box,
                        });
                        lastFrame = aiList[aiList.length - 1].current_frame
                    }
                })
            });
            return aiList
        },

        /**
         * 탐지한 객체 지도에 마커 찍기
         */
        setAiDetectedMarker() {
            this.aiObservedList.forEach((aiData, index) => {
                const locationNumber = Math.floor(aiData.frame / 30)

                let markerType = MapDataType.ID_MAP_AI_MARKER
                let mapMarkerData = new MapMarkerData();
                mapMarkerData.id = index;
                mapMarkerData.isMain = true;

                mapMarkerData.lat = this.location[locationNumber].lat;
                mapMarkerData.lng = this.location[locationNumber].lng;
                if (this.$refs.map.onIsMarker(markerType, index)) {
                    this.$refs.map.onModifyMarkerAt(markerType, mapMarkerData);
                } else {
                    this.$refs.map.onCreateMarker(markerType, mapMarkerData);
                }
            })
        },

        /**
         * 비디오 정보 Detail
         */
        async getVodInfo() {
            this.isPlayStatus = false;
            const res = await fetchVodDetail(this.contentsId)
            if (res.data.result !== 0) { return }
            return res.data.data
        },

        /**
         * 이동좌표 리스트 GET
         */
        async getLocation() {
            this.initDoneData();
            this.location = [];
            this.locationSize = null;

            this.$refs.map.onPlayRemoveData()
            this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);

            let params = {
                contentsId: this.contentsId,
            };

            const res = await fetchLocation(params)
            this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
            if (res.data.result !== 0) { return }

            this.locationSize = res.data.data.content.length;
            if (this.locationSize == 0) { return }

            const result = res.data.data.content.sort((a, b) => {
                return a.index - b.index;
            })
            return result
        },

        playerLoadData(id, isLoad) {
            this.isPlayStatus = isLoad;
        },
        playerReady() {
            this.$refs.video.onPlayerAddDom("video-info-box");
        },
        playerOpen() {
            playerPopOpen("vod", this.contentsId);
        },
        onUpdateAiDetecting(aiDetecting) {
            console.log(aiDetecting)
        },
        /**
         * 드론 init
         */
        initDoneData() {
            this.doneData.distance = null;
            this.doneData.hspeed = null;
            this.doneData.vspeed = null;
            this.doneData.height = null;
            this.doneData.batte1ry = null;
            this.doneData.heartRate = null;
        },

        /**
         * 영상에 있는 드론 정보
         * @param lng
         * @param lat
         * @param index
         */
        playMarkerLngLat(lng, lat, index) { //index로 vod 부분에서 꺼내 사용하는 데이터 번호 넘겨주기.
            this.$refs.map.onSetLngLat(lng, lat);
            if (this.location.length > 0) {
                let locationData = this.location[index];
                this.doneData.distance = locationData.distance;
                this.doneData.hspeed = locationData.hspeed;
                this.doneData.vspeed = locationData.vspeed;
                this.doneData.height = locationData.height;
                this.doneData.battery = locationData.battery;
                this.doneData.heartRate = locationData.heartRate;

                this.currentLng = lng;
                this.currentLat = lat;
            }
        },

        async getEvent() {
            const res = await fetchEvent(this.eventId)
            if (res.data.result !== 0) { return }
            const result = res.data.data;
            return result

            // this.initMapData(this.currentLang);

            // if (this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
            //     // this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
            //     this.$refs.map.onCreateMapMarker(this.eventId, this.eventInfo.lng, this.eventInfo.lat, MapDataType.ID_SITE);
            //     // this.$refs.map.onSetLngLat(this.eventInfo.lng, this.eventInfo.lat);
            // } else {
            //     this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
            // }
        },

        getEventPoint() {
            let params = {
                eventId: this.eventId,
                serverTime: null,
            };

            fetchPointList(params).then((res) => {
                if (res.data.result === 0) {
                    let pointList = res.data.data.content

                    pointList.forEach((element) => {
                        element.selectId = element.index;
                        element.selectType = "Point";
                        element.isOwner = false;
                        let pointMapMarker = new MapMarkerData();
                        element.name = this.$t(getPointTypeName(element.type));
                        pointMapMarker.setPointData(element);
                        this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
                    })
                    this.$refs.map.setLayout();
                }
            });
        },


        onTimeUpdate(time) {
            if (this.vodObj != null) {
                let changeTIme = moment(new Date(this.vodObj.recordDate)).add(time * 1000, 'millisecond')
                this.changePlayTime = changeTIme.format("YYYY-MM-DD HH:mm:ss");
            }
            if (this.locationSize > 0) {
                this.$refs.map.onPlayUpdatePosition(time);
            }
        },

        deleteVod() {
            alert("컨텐츠가 삭제되었습니다.")
            this.$router.go(-1)
        },

        frameToTime(frame) {
            const fps = 30;  // 초당 프레임 수
            const seconds = frame / fps;  // 총 초
            const duration = moment.duration(seconds, 'seconds');
            return moment(duration.asMilliseconds()).format("mm:ss");
        },


        percentage(value) {
            return `${(value * 100).toFixed(1)}`;
        },
        customFormatter(date) {
            return moment(date).format("YYYY년 MM월 DD일");
        },
        initMapData(value) {

            if (value == 'en') {
                this.loadMap = 'google'
            } else {
                this.loadMap = 'kakao'
            }
            this.getEventPoint();

        },
        initButtonMap() {
            this.$refs.buttonMap.showMapTypeChange()
            this.$refs.buttonMap.showMapCenter()
        },

    },
};

</script>

<style>
.title {
    display: none;
}

.back-btn {
    margin-top: 50px;
    border: 1px solid #ddd;
    padding: 0 30px;
    background: #fff;
    color: #454545;
    border-radius: 5px;
}

.m-b-0 {
    margin-bottom: 0 !important;
}

.mapWrap {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
}

.mapWrap>.mapWrap-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.mapWrap>.mapWrap-title>h2 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: bold;
}

.mapWrap>.mapWrap-title>button {
    background: #fff;
    border: 1px solid #0080FF;
    border-radius: 5px;
    color: #0080FF;
    font-weight: 400;
}

.vodTitle {
    color: #808080;
    font-size: 1.2rem;
}

.grayW {
    color: #808080;
}

.droneData {
    margin-left: 10px;
}

.droneData:nth-child(1) {
    margin-left: 0;
}

.vodData {
    color: #000;
    font-size: 1.4rem;
    font-weight: bold;
}

.DataName {
    color: #6ebeff;

}

.vod-site-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
}

.vod-site>.vodData {
    color: #969696;
}

.vod-number {
    padding: 3px 0px;
    border-radius: 5px;
    color: #0080ff !important;
}

.vod-site-title {
    margin-top: 5px;
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.vod-device-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10px;
}

.vod-device-info:nth-child(2) {
    margin-bottom: 0px;
}

.vod-device-info>span:nth-child(2) {
    color: #454545;
    font-size: 1.2rem !important;
    font-weight: 400;
}

.vod-device {
    width: 250px;
}

.vod-device-info>.vodTitle>img {
    margin-right: 5px;
    vertical-align: baseline;
}

.droneVal {
    display: flex;
    flex-direction: column;
}

.droneVal>.droneVal-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.droneVal>.droneVal-row:nth-child(1) {
    padding-top: 15px;
    margin-bottom: 15px;
}

.droneVal>.droneVal-row>.droneData {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.droneVal>.droneVal-row:nth-child(1)>.droneData:nth-child(1) {
    width: 25%;
}

.droneVal>.droneVal-row:nth-child(1)>.droneData:nth-child(2) {
    width: 25%;
}

.droneVal>.droneVal-row:nth-child(1)>.droneData:nth-child(3) {
    width: 25%;
}

.droneVal>.droneVal-row:nth-child(1)>.droneData:nth-child(4) {
    width: 25%;
}

.droneVal>.droneVal-row:nth-child(2)>.droneData:nth-child(1) {
    width: 50%;
}

.droneVal>.droneVal-row:nth-child(2)>.droneData:nth-child(2) {
    width: 50%;
}

.droneVal>.droneVal-row>.droneData>.DataName {
    background: #eee;
    color: #969696;
    padding: 3px 15px;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 400;
}

.vodlist>h2 {
    margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
}

/*사진캡처 버튼*/
.imgCapt {
    width: 96px;
    height: 36px;
    text-align: center;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
    background: white;
    border-radius: 4px;
    position: absolute;
    z-index: 2000;
    margin: 10px;
    padding: 6px 10px;
    color: black;
    border: 1px solid white;
}

.imgCapt>span {
    line-height: 23px;
    font-weight: bold;
    font-size: 1.2rem;
}

.imgCapt:hover {
    background: #94d9ef;
}

.vod-ai-mark {
    position: absolute;
    z-index: 10;
    left: 10px;
    top: 10px;
    background: #0b0a11b5;
    padding: 11px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
}

.vod-ai-mark>.vod-ai-btn {
    display: flex;
    align-items: center;
}

.vod-ai-mark>.vod-ai-btn>label {
    font-size: 1.2rem;
    color: #fff;
    margin-right: 10px;
}

/* .vjs-tech{object-fit: cover;} */
.video-player .video-js {
    width: 100%;
    height: 100%;
    padding-top: 56.25%;
    border-radius: 5px;
}

.video-info-box {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0%);
    background: #1010108a;
    padding: 5px 20px;
    color: #fff;
    border-radius: 5px;
    z-index: 100;
}

.video-info-box>span {
    font-size: 1.4rem;
}

.infoContent {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
}

.aiTableInfo {
    width: 39%;
}

.detailAIVod {
    width: 60%;
}

.detailAIVod>h1 {
    font-size: 2rem;
    margin-bottom: 5px;
}

.detailAIVod>h4 {
    margin-bottom: 10px;
    font-size: 1.4rem;
    color: #101010;
}

.detailAIVod>h4>span {
    font-size: 1.4rem;
    font-weight: 400;
}

.detail-info-box {
    background: #f7f7f7;
    padding: 15px 10px;
    border-radius: 5px;
}

.detail-info-box>.info-row {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.detail-info-box>.info-row>h4 {
    font-size: 1.4rem;
    color: #969696;
    font-weight: 400;
    width: 150px;
}

.detail-info-box>.info-row>h2 {
    font-size: 1.4rem;
    margin: 0;
}

.ai-table {
    height: 220px;
    max-height: 220px;
    overflow-y: scroll;
}

.ai-table>table>tr>th {}
</style>
